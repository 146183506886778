import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  selectFlight() {
    console.log("selectFlight called for "+ this.element)
    let selectedFlight = this.element
    let searchResultsContainer = document.getElementById("flight-results")
    let flightFormInputPrefix = searchResultsContainer.dataset.flightForm
    let existingSelectedFlights = searchResultsContainer.querySelectorAll(".selected")

    existingSelectedFlights.forEach(function(el) {
      selectedFlight.classList.replace("hover:border-blue-600", "hover:border-gray-200")

      el.classList.remove("selected")
      el.classList.replace("border-blue-500", "border-white")
    })

    selectedFlight.classList.add("selected")
    selectedFlight.classList.replace("border-white", "border-blue-500")
    selectedFlight.classList.replace("hover:border-gray-200", "hover:border-blue-600")

    let departureAirportId = selectedFlight.dataset.departureAirportId
    let departsAt = selectedFlight.dataset.departsAt
    let arrivalAirportId = selectedFlight.dataset.arrivalAirportId
    let arrivesAt = selectedFlight.dataset.arrivesAt
    
    let departureAirportIdInput = document.getElementsByName(`${flightFormInputPrefix}[departure_airport_id]`)[0]
    let departsAtInput = document.getElementsByName(`${flightFormInputPrefix}[departs_at]`)[0]
    let arrivalAirportIdInput = document.getElementsByName(`${flightFormInputPrefix}[arrival_airport_id]`)[0]
    let arrivesAtInput = document.getElementsByName(`${flightFormInputPrefix}[arrives_at]`)[0]
    
    departureAirportIdInput.value = selectedFlight.dataset.departureAirportId
    departsAtInput.value = selectedFlight.dataset.departsAt
    arrivalAirportIdInput.value = selectedFlight.dataset.arrivalAirportId
    arrivesAtInput.value = selectedFlight.dataset.arrivesAt
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clear() {
    console.log("Clear event called!")
    console.log(event.detail)
    if (event.detail.success) {
      this.element.reset()
    }
  }
}

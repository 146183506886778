import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

Date.prototype.yyyymmdd = function() {
  var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
  var dd = this.getDate().toString();
  
  var month = (mm.length === 2 ? '' : '0') + mm
  var day = (dd.length === 2 ? '' : '0') + dd

  return [this.getFullYear(), month, day].join('-')
};
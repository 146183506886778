import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inviteeList", "additionalInput" ]

  add(event) {
    event.preventDefault()
    console.log(this.additionalInputTarget)
    let newInput = this.additionalInputTarget.cloneNode()
    newInput.classList.replace("!hidden", "block")
    newInput.value = ""
    this.inviteeListTarget.appendChild(newInput)
  }
}

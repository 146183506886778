import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "imageUrl", "image", "imagePlaceholder", "customImage", "removeImageAttachment" ]
  
  connect() {
    console.log("images connected")
  }
  
  removeImage() {
    this.imagePlaceholderTarget.classList.replace("hidden", "block")
    this.imageTarget.classList.replace("block", "hidden")
    this.imageUrlTarget.value = null
    this.customImageTarget.value = null
    this.removeImageAttachmentTarget.value = "true"
  }
  
  attachCustomImage() {
    let files = this.customImageTarget.files
    
    if(!Array.isArray(files) && !files.length) {
      return
    }
  
    let file = this.customImageTarget.files[0]
    let customFileUrl = URL.createObjectURL(file)
    console.log(customFileUrl)
    this.imageUrlTarget.value = null
    this.removeImageAttachmentTarget.value = null
    this.imagePlaceholderTarget.classList.replace("block", "hidden")
    this.imageTarget.classList.replace("hidden", "block")
    this.imageTarget.src = customFileUrl
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "monthlyToggle", "yearlyToggle", "monthlyPrice", "yearlyPrice", "monthlyCheckoutLink", "yearlyCheckoutLink" ]

  selectMonthly(event) {
    event.preventDefault()
    this.monthlyToggleTarget.classList.replace("bg-white", "bg-[#e9effd]")
    this.monthlyToggleTarget.classList.replace("text-gray-600", "text-blue-600")
    this.yearlyToggleTarget.classList.replace("bg-[#e9effd]", "bg-white")
    this.yearlyToggleTarget.classList.replace("text-blue-600", "text-gray-600")
    this.monthlyPriceTarget.classList.replace("hidden", "block")
    this.yearlyPriceTarget.classList.replace("block", "hidden")
    if(this.hasMonthlyCheckoutLinkTarget) {
      this.monthlyCheckoutLinkTarget.classList.replace("hidden", "block")
    }
    if(this.hasYearlyCheckoutLinkTarget) {
      this.yearlyCheckoutLinkTarget.classList.replace("block", "hidden")
    }
  }

  selectYearly(event) {
    event.preventDefault()
    this.monthlyToggleTarget.classList.replace("bg-[#e9effd]", "bg-white")
    this.monthlyToggleTarget.classList.replace("text-blue-600", "text-gray-600")
    this.yearlyToggleTarget.classList.replace("bg-white", "bg-[#e9effd]")
    this.yearlyToggleTarget.classList.replace("text-gray-600", "text-blue-600")
    this.monthlyPriceTarget.classList.replace("block", "hidden")
    this.yearlyPriceTarget.classList.replace("hidden", "block")
    if(this.hasMonthlyCheckoutLinkTarget) {
      this.monthlyCheckoutLinkTarget.classList.replace("block", "hidden")
    }
    if(this.hasYearlyCheckoutLinkTarget) {
      this.yearlyCheckoutLinkTarget.classList.replace("hidden", "block")
    }
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mobileNavigation", "menuIcon", "closeIcon" ]

  toggle(event) {
    event.preventDefault()
    console.log("Toggle clicked!")

    if(this.menuIconTarget.classList.contains("block")) {
      this.menuIconTarget.classList.replace("block", "hidden")
      this.closeIconTarget.classList.replace("hidden", "block")
    } else {
      this.menuIconTarget.classList.replace("hidden", "block")
      this.closeIconTarget.classList.replace("block", "hidden")
    }

    this.mobileNavigationTarget.classList.toggle("hidden")
    this.mobileNavigationTarget.classList.toggle("block")
  }
}

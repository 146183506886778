import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon"

export default class extends Controller {
  static targets = [ "startDate", "endDate" ]
  
  static values = {
    offset: Number,
    kind: String
  }
  
  initialize() {
    this.endDateMutated = false
  }
  
  startDateChanged() {
    if (!this.endDateMutated) {
      let startDate = DateTime.fromISO(this.startDateTarget.value)
      let endDate = startDate.plus({ days: this.offsetValue })
      
      if(this.kindValue == "DateTime"){
        this.endDateTarget.value = endDate.toFormat("y-LL-dd'T'HH:mm")
      } else {
        this.endDateTarget.value = endDate.toISODate()
      }
    }
  }

  endDateChanged() {
    this.endDateMutated = true
  }
}

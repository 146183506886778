import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "marketingAirlineId", "flightNumber", "searchDate" ]
  
  connect() {
    const targetNode = document.getElementById("flight-results")

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        let flightResults = targetNode.querySelectorAll(".flight-result")
        
        if(flightResults.length > 0) {
          flightResults[0].click()
        } else {
          let manualEntryContainers = document.querySelectorAll(".manual-entry")
          manualEntryContainers.forEach(function(el) {
            el.classList.replace("hidden", "block")
          })
        }
      }
    };

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, { childList: true })
  }
  
  search() {
    fetch(this.constructUrl, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
  
  get constructUrl() {
    return `/flight_schedules?airline_id=${this.marketingAirlineId}&flight_number=${this.flightNumber}&date=${this.searchDate}`
  }
  
  get marketingAirlineId() {
    return this.marketingAirlineIdTarget.value
  }
  
  get flightNumber() {
    return this.flightNumberTarget.value
  }
  
  get searchDate() {
    return this.searchDateTarget.value
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "startSearch",
    "startFormattedAddress",
    "startLatitude",
    "startLongitude",
    "startCountry",
    "startTimezone",
    "startPlaceId",
    "startPlaceName",
    "startPhone",
    "startWebsite",
    "endSearch",
    "endFormattedAddress",
    "endLatitude",
    "endLongitude",
    "endCountry",
    "endTimezone",
    "endPlaceId",
    "endPlaceName",
    "endPhone",
    "endWebsite",
    "imageUrl",
    "image",
    "imagePlaceholder",
    "customImage",
    "removeImageAttachment",
    "locationIds",
    "statusIndicator"
  ]
  
  static values = {
    chainedLocations: Boolean
  }
  
  connect() {
    this.endLocationMutated = false
    this.setSearchToken("start")
    this.startSearchInput = this.startSearchTarget.querySelector('input[role="combobox"]')
    this.localCache = new Map()

    if(this.hasEndSearchTarget){
      this.setSearchToken("end")
      this.endSearchInput = this.endSearchTarget.querySelector('input[role="combobox"]')
    }
  }
  
  startSelected() {
    let startSearchElement = this.startSearchTarget.getElementsByTagName("input")[0]
    let startPlaceId = startSearchElement.value
    
    if (!startPlaceId) {
      return
    }
    
    this.fetchDetails("start", startPlaceId, this.startSearchToken)
    this.setSearchToken("start")
  }

  endSelected() {
    let endSearchElement = this.endSearchTarget.getElementsByTagName("input")[0]
    let endPlaceId = endSearchElement.value
    
    if (!endPlaceId) {
      return
    }

    this.endLocationMutated = true
    this.fetchDetails("end", endPlaceId, this.endSearchToken)
    this.setSearchToken("end")
  }
  
  fetchDetails(kind, placeId, sessionToken) {
    if(this.hasStatusIndicatorTarget){
      this.statusIndicatorTarget.classList.replace("hidden", "block")
    }

    if(this.localCache.has(placeId)) {
      let place = this.localCache.get(placeId)
      this.setDetails(kind, place)
      return
    }
    
    const url = `/api/v1/autocomplete/${placeId}?session_token=${sessionToken}`
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(place => {
      this.localCache.set(placeId, place)
      this.setDetails(kind, place)

      if(this.hasStatusIndicatorTarget){
        this.statusIndicatorTarget.classList.replace("block", "hidden")
      }
    })
  }
  
  setDetails(kind, place) {
    if(!(kind == "start" || kind == "end")) {
      return
    }
    
    if(!place) {
      return
    }
    
    this[kind + "FormattedAddressTarget"].value = place.formatted_address
    this[kind + "LatitudeTarget"].value = place.latitude
    this[kind + "LongitudeTarget"].value = place.longitude
    this[kind + "TimezoneTarget"].value = place.timezone
    this[kind + "PlaceIdTarget"].value = place.place_id
    
    if(kind == "start" && this.hasLocationIdsTarget) {
      this.locationIdsTarget.value = [place.location_id]
    }

    if(kind == "start" && this.hasStartPlaceNameTarget) {
      this.startPlaceNameTarget.value = place.name
    }
    
    if(kind == "start" && this.hasStartPhoneTarget) {
      this.startPhoneTarget.value = place.phone
    }

    if(kind == "start" && this.hasStartWebsiteTarget) {
      this.startWebsiteTarget.value = place.website
    }
    
    if(kind == "start" && this.hasStartCountryTarget) {
      this.startCountryTarget.value = place.country_code
    }
    
    if(kind == "start" && this.hasImageUrlTarget && place.image_url != null) {
      this.imageUrlTarget.value = place.image_url
      this.imagePlaceholderTarget.classList.replace("block", "hidden")
      this.imageTarget.classList.replace("hidden", "block")
      this.imageTarget.src = place.image_url
    }
    
    if(kind == "end" && this.hasEndPhoneTarget) {
      this.endPhoneTarget.value = place.phone
    }
    
    if(kind == "end" && this.hasEndWebsiteTarget) {
      this.endWebsiteTarget.value = place.website
    }
    
    if(kind == "end" && this.hasEndPlaceNameTarget) {
      this.endPlaceNameTarget.value = place.name
    }
    
    if(kind == "end" && this.hasEndCountryTarget) {
      this.endCountryTarget.value = place.country_code
    }

    if(this.chainedLocationsValue && kind == "start" && this.hasEndSearchTarget && !this.endLocationMutated){
      this.endSearchInput.value = this.startSearchInput.value
      this.endFormattedAddressTarget.value = place.formatted_address
      this.endLatitudeTarget.value = place.latitude
      this.endLongitudeTarget.value = place.longitude
      this.endTimezoneTarget.value = place.timezone
      this.endPlaceIdTarget.value = place.place_id
      this.endPlaceNameTarget.value = place.name
      this.endPhoneTarget.value = place.phone
      this.endCountryTarget.value = place.country_code
    }
  }

  setSearchToken(kind) {
    let searchToken = self.crypto.randomUUID()
    var target = null
    if(kind == "start") {  // Used for rental car pickup and transit start location search
      this.startSearchToken = searchToken
      target = this.startSearchTarget
    } else {  // 'end' Used for rental car dropoff and transit end location search
      this.endSearchToken = searchToken
      target = this.endSearchTarget
    }
    
    var searchUrl = target.dataset.hwComboboxAsyncSrcValue
    if(searchUrl.includes("&session_token")) {
      let tokenIndex = searchUrl.indexOf("&session_token")
      searchUrl = searchUrl.slice(0, tokenIndex)
    }
    
    searchUrl = `${searchUrl}&session_token=${searchToken}`
    target.dataset.hwComboboxAsyncSrcValue = searchUrl
  }
  
  removeImage() {
    this.imagePlaceholderTarget.classList.replace("hidden", "block")
    this.imageTarget.classList.replace("block", "hidden")
    this.imageUrlTarget.value = null
    this.customImageTarget.value = null
    this.removeImageAttachmentTarget.value = "true"
  }
  
  attachCustomImage() {
    let files = this.customImageTarget.files
    
    if(!Array.isArray(files) && !files.length) {
      return
    }

    let file = this.customImageTarget.files[0]
    let customFileUrl = URL.createObjectURL(file)
    console.log(customFileUrl)
    this.imageUrlTarget.value = null
    this.removeImageAttachmentTarget.value = null
    this.imagePlaceholderTarget.classList.replace("block", "hidden")
    this.imageTarget.classList.replace("hidden", "block")
    this.imageTarget.src = customFileUrl
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bookmarkUrl", "bookmarkTitle", "statusMessage", "statusIndicator", "titleErrorMessage", "image", "imageUrl", "imagePlaceholder", "customImage", "removeImageAttachment" ]

  search() {
    let searchUrl = this.bookmarkUrlTarget.value
    this.fetchUrlDetail(searchUrl)
  }
  
  fetchUrlDetail(searchUrl) {
    this.statusMessageTarget.textContent = ""
    this.titleErrorMessageTarget.textContent = ""

    if(searchUrl == ""){
      return
    }

    this.statusIndicatorTarget.classList.replace("hidden", "block")
  
    const url = `/api/v1/opengraph`
    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ url: searchUrl })
    })
    .then(response => {
      this.statusIndicatorTarget.classList.replace("block", "hidden")

      if (!response.ok) {
        const error = new Error(`Error fetching details (${response.status})`);
        error.status = response.status;
        throw error;
      }
      return response.json();
    })
    .then(detail => {
      this.bookmarkTitleTarget.value = detail.title
      this.imageUrlTarget.value = detail.image_url
      this.imagePlaceholderTarget.classList.replace("block", "hidden")
      this.imageTarget.classList.replace("hidden", "block")
      this.imageTarget.src = detail.image_url
    })
    .catch(error => {
      if (error.status === 422) {
        this.statusMessageTarget.textContent = "Invalid URL"
      } else if (error.status === 500) {
        this.titleErrorMessageTarget.textContent = "Unable to automatically fetch URL title"
      } else {
        console.log("Unknown error:", error)
      }
    });
  }

  attachCustomImage() {
    let files = this.customImageTarget.files
    
    if(!Array.isArray(files) && !files.length) {
      return
    }

    let file = this.customImageTarget.files[0]
    let customFileUrl = URL.createObjectURL(file)
    this.imageUrlTarget.value = null
    this.removeImageAttachmentTarget.value = null
    this.imagePlaceholderTarget.classList.replace("block", "hidden")
    this.imageTarget.classList.replace("hidden", "block")
    this.imageTarget.src = customFileUrl
  }
}
